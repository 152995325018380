const roundToDecimalPlaces = (num, decimalPlaces) => {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(num * factor) / factor;
};

const convertEthToUsd = async (eth) => {
  const apiUrl =
    "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd";

  try {
    const response = await fetch(apiUrl);
    const data = await response.json();
    const ethToUsdRate = data.ethereum.usd;
    const usdAmount = roundToDecimalPlaces(eth * ethToUsdRate, 2);
    setUsdValue(usdAmount);
  } catch (error) {
    return "NaN";
  }
};

export { roundToDecimalPlaces, convertEthToUsd };
